import { NavLink } from 'react-router-dom';
import { DateTime } from 'luxon';

export default function Episode({ userEpisode }) {
	const { episode } = userEpisode;
	const dt = DateTime.fromISO(userEpisode.added || episode.date);
	const mins = Math.round(episode.duration / 60);

	return (
		<NavLink className="episode" key={episode.id} to={'/transcript/' + episode.id} state={{episode}}>
			<div className="episode-image">
				<img src={episode.podcast.appleInfo.artworkUrl600} />
			</div>
			<div className="episode-info">
				<div className="episode-podcast">{episode.podcast.appleInfo.collectionName} - {dt.toISODate()} - {mins}m</div>
				<div className="episode-title">{episode.title}</div>
			</div>
		</NavLink>
	);
}