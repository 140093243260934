import React, { useState, useEffect, useRef } from 'react';
import { useLoaderData, useParams, useLocation } from "react-router-dom";
import API from '../../api';
import useNav from '../../models/nav';
import Episode from '../episode';

export default function TranscriptPage() {
	const params = useParams();
	const {episodeId} = params;

	let [loading, setLoading] = useState(true);
	let [processingTime, setProcessingTime] = useState(5);
	const [data, setData] = useState(null);
	const nav = useNav();
	const location = useLocation();

	async function fetch() {
		try {
			const r = await API.call('episode/get', { id: episodeId });
			setData(r);

			if (!r.transcript?.lines?.length) {
				// if we don't have a transcript yet, then let's check again in 5 seconds
				setTimeout(() => {
					fetch();
				}, 5000);
			}
		} catch (e) {
			console.log('- fail', e);
		}
		setLoading(false);
	};

	useEffect(() => {
		
		nav.title = '';

		// if we're coming from the inbox page, then we already have the basic info, so let's store it
		if (location.state?.episode) {
			setData(location.state.episode);
		}

		// no matter what, we need to get the full episode data with transcript
		fetch();

	}, []);

	return (
		<div key="transcript-page" className="page">
			<div key="subtitle" className="subtitle">
				{data && (
					<>
						<Episode userEpisode={{episode: data}}/>
						<Player episode={data}/>
					</>
				)}
			</div>
			<hr/>
			{loading && (
				<div key="loading">Loading...</div>
			)}
			{!loading && !!data.transcript?.lines?.length && (
				<div key="transcript" className="transcript">
					<h2>Transcript</h2>
					{data.transcript.lines.map((line, ii) => (
						<TranscriptLine key={ii} line={line}/>
					))}
				</div>
			)}
			{!loading && !data.transcript?.lines?.length && (
				<div key="processing">
					Processing transcript. Please wait.
				</div>
			)}
		</div>
	);
};

function TranscriptLine({line}) {

	function formatTime(ms) {
		const seconds = Math.floor(Math.abs(ms / 1000))
		const h = Math.floor(seconds / 3600)
		const m = Math.floor((seconds % 3600) / 60)
		const s = Math.round(seconds % 60)
		const t = [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s]
			.filter(Boolean)
			.join(':')
		return ms < 0 && seconds ? `-${t}` : t
	}
	
	const start = line.words[0].start ?? null;
	const timecode = start ? formatTime(start) : '';
	
	return (
		<div className={'transcript-line' + (line.ad ? ' ad' : '')}>
			<div key="speaker" className="transcript-speaker">{line.speakerName} <span>{timecode}</span></div>
			<div key="content" className="transcript-content">{line.words.map(word => {
				return (
					<span id={word.start} key={word.start}>{word.text} </span>
				);
			})
			}</div>
		</div>
	);
}

function Player({episode}) {
	const audioRef = useRef(null);

	function addClassToElement(selector, className) {
		const elements = document.querySelectorAll(selector);
		elements.forEach(element => {
			element.className += ' ' + className;
		});
	}

	// Function to remove a class from an element
	function removeClassFromElement(selector, className) {
		const elements = document.querySelectorAll(selector);
		elements.forEach(element => {
			element.className = element.className.replace(new RegExp('(?:^|\\s)' + className + '(?:\\s|$)'), ' ');
		});
	}

	useEffect(() => {
		const audio = audioRef.current;

		audio.addEventListener('play', function() {
			console.log('play');
		});
		audio.addEventListener('pause', function() {
			console.log('pause');
		});
		audio.addEventListener('ended', function() {
			console.log('ended');
		});
		audio.addEventListener('timeupdate', function() {
			//console.log('timeupdate', audio.currentTime);
			const currentTime = audio.currentTime * 1000; // ms

			// remove the current word highlight
			const lastWordEl = document.querySelector('.current-word');
			if (lastWordEl) {
				lastWordEl.className = lastWordEl.className.replace(new RegExp('(?:^|\\s)' + 'current-word' + '(?:\\s|$)'), ' ');
			}

			// find the current line
			let currentLine = null;
			for (let line of episode.transcript.lines) {
				if (line.start < currentTime && line.end > currentTime) {
					currentLine = line;
					break;
				}
			}

			if (!currentLine) return;
			//console.log('- found line:', currentLine);

			// find the current word
			let currentWord = null;
			for (let word of currentLine.words) {
				if (word.start < currentTime && word.end > currentTime) {
					currentWord = word;
					break;
				}
			}
			if (!currentWord) return;

			// highlight the current word
			const currentWordEl = document.getElementById(currentWord.start);
			if (currentWordEl) {
				currentWordEl.className += ' current-word';
			}
		});
	}, []);

	return (
		<audio ref={audioRef} controls src={episode.mediaUrl}></audio>
	);
}
