import { useState, useEffect } from 'react';
import useNav from '../../models/nav';


export default function TermsPage() {
	const nav = useNav();

	function reload() {
		window.location.reload();
	}

	useEffect(() => {
		nav.title = 'Terms of Service';
	}, []);

	return (
		<>
			<div>
				<p><strong>Effective Date:</strong> January 25th, 2024</p>

				<h2>1. Acceptance of Terms</h2>
				<p>Welcome to Sezhoo! By using our app, you agree to these Terms of Service. If you do not agree, please do not use Sezhoo.</p>

				<h2>2. Use of Sezhoo</h2>
				<ul>
					<li>You must be at least 18 years old to use Sezhoo.</li>
					<li>You agree to provide accurate and complete information when using our app.</li>
					<li>You are responsible for maintaining the confidentiality of your account credentials.</li>
					<li>You are solely responsible for your actions and content shared through Sezhoo.</li>
				</ul>

				<h2>3. Privacy</h2>
				<p>Your use of Sezhoo is also governed by our <a href="/privacy">Privacy Policy</a>. Please review it to understand how we collect, use, and protect your personal information.</p>

				<h2>4. User Content</h2>
				<p>By using Sezhoo, you grant us a non-exclusive license to use, reproduce, and display the content you share on the app. You are responsible for ensuring your content complies with applicable laws and does not infringe on any third-party rights.</p>

				<h2>5. Prohibited Activities</h2>
				<p>You agree not to:</p>
				<ul>
					<li>Use Sezhoo for any unlawful or harmful purposes.</li>
					<li>Harass, impersonate, or intimidate others on the app.</li>
					<li>Use automated tools to access or collect data from Sezhoo.</li>
					<li>Violate any applicable laws or regulations while using our app.</li>
				</ul>

				<h2>6. Termination</h2>
				<p>We reserve the right to terminate or suspend your account at our discretion, without notice, if you violate these Terms of Service.</p>

				<h2>7. Limitation of Liability</h2>
				<p>Sezhoo is provided "as is," and we make no warranties regarding its accuracy, reliability, or availability. We are not liable for any direct or indirect damages arising from your use of the app.</p>

				<h2>8. Changes to Terms</h2>
				<p>We may update these Terms of Service at any time. Your continued use of Sezhoo after any changes constitutes your acceptance of the revised terms.</p>

				<h2>9. Contact Us</h2>
				<p>If you have any questions or concerns about these Terms of Service, please contact us at <b>support@sezhoo.app</b>.</p>

				<p>Thank you for using Sezhoo!</p>
			</div>
		</>
	);
	
}