import { useState, useEffect } from 'react';
import useNav from '../../models/nav';


export default function PrivacyPage() {
	const nav = useNav();

	function reload() {
		window.location.reload();
	}

	useEffect(() => {
		nav.title = 'Privacy Policy';
	}, []);

	return (
		<>
			<div>
				<p><strong>Effective Date:</strong> Jan 25th, 2024</p>

				<h2>1. Introduction</h2>
				<p>Welcome to Sezhoo! This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use our app. By using Sezhoo, you consent to the practices described in this policy.</p>

				<h2>2. Information We Collect</h2>
				<p>We collect two types of personal information:</p>
				<ul>
					<li><strong>Name:</strong> We collect your name to personalize your experience and address you by your name.</li>
					<li><strong>Email:</strong> We collect your email to communicate with you, send updates, allow logins, and provide support.</li>
				</ul>

				<h2>3. How We Use Your Information</h2>
				<p>We use your name and email for the following purposes:</p>
				<ul>
					<li>To personalize your experience on Sezhoo.</li>
					<li>To send you important updates and information.</li>
					<li>To respond to your inquiries and provide customer support.</li>
				</ul>

				<h2>4. Data Security</h2>
				<p>We take reasonable measures to protect your personal information. However, no data transmission over the internet can be guaranteed to be 100% secure. Please use Sezhoo at your own discretion.</p>

				<h2>5. Sharing Your Information</h2>
				<p>We do not sell, trade, or rent your personal information to third parties. Your information is only shared with our trusted service providers who help us operate Sezhoo.</p>

				<h2>6. Your Choices</h2>
				<p>You can update your name and email in your Sezhoo account settings. You can also unsubscribe from our emails at any time.</p>

				<h2>7. Changes to this Privacy Policy</h2>
				<p>We may update this policy from time to time. Please check for changes periodically. Your continued use of Sezhoo after any updates constitutes your acceptance of the revised policy.</p>

				<h2>8. Contact Us</h2>
				<p>If you have any questions or concerns about this Privacy Policy or your personal information, please contact us at <b>support@sezhoo.app</b>.</p>

				<p>Thank you for using Sezhoo!</p>
			</div>
		</>
	);
	
}