import { createModel } from './model';
import { createClient } from '@supabase/supabase-js';

const useAuth = createModel({
	client: createClient('https://anuffhrbwikjlfgarerq.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFudWZmaHJid2lramxmZ2FyZXJxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDEwMzE1MjgsImV4cCI6MjAxNjYwNzUyOH0.9J4N5DN-vDrg4ZwQYX1wU2NVljHbG6HOjvutZV59grM'),
	user: null,
	sessionLoaded: false,
	session: null,
	subscription: null,
	async getSession() {
		const r = await this.client.auth.getSession();
		this.session = r.data.session;
		this.sessionLoaded = true;
		return this.session;
	},
	subscribeToChanges() {
		const {
			data: { subscription },
		} = this.client.auth.onAuthStateChange((_event, session) => {
			this.session = session;
		});
		this.subscription = subscription;
	},
	unsubscribeFromChanges() {
		this.subscription.unsubscribe();
	},
	async signOut() {
		console.log('- signing out...');
		const { error } = await this.client.auth.signOut();
		if (error) console.error('- signout error:', error);
	}

}, {debug: false});

export default useAuth;