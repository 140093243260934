import request from 'superagent';
import useAuth from './models/auth';

export default class API {

	public static domain = 'https://sezhoo.app/api/';

	/**
	 * Makes a call to the Sezhoo server, automatically appending the supabase jwt
	 * @param path - the path on the api to hit. No preceding slash
	 * @param vars - the post vars to include, if any
	 * @param silent - whether or not to output the request to the console
	 * @return the response json object
	 */
	public static async call(path: string, vars: any = {}, silent: boolean = false) {
		const auth = (useAuth as any).get();
		const start = Date.now();
		const r = await request.post(this.domain + path)
			.set('Authorization', auth.session?.access_token)
			.type('form')
			.send(vars);

		const dur = Date.now() - start;
		const data = JSON.parse(r.text);
		if (!silent) console.log('%c[API]', 'color: #CCC;', dur + 'ms', path, data);

		return data;
	}
};